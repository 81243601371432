import { db } from "../firebase";
import { Builder } from "../store/types";

export const BUILDER_PAGE_INT = 50;

export const fetchBuilders = async (name?: string) => {
  let startAfterName = "";
  if (name !== undefined) {
    startAfterName = name;
  }

  const builders: Array<Builder> = await db
    .collection("Builders")
    .orderBy("Builder_Name")
    .startAfter(startAfterName)
    .limit(BUILDER_PAGE_INT)
    .get()
    .then((querySnapshot) => {
      return querySnapshot.docs.map((doc) => {
        return {
          ...doc.data(),
        } as Builder;
      });
    });
  return builders;
};

export const fetchBuilder = async (id: string) => {
  const builder: Builder | undefined = await db
    .collection("Builders")
    .doc(id)
    .get()
    .then((doc) => {
      const data = doc.data();
      if (data === undefined) {
        return;
      }
      return data as Builder;
    });
  return builder;
};
