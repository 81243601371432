import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  TextField,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { auth } from "../../firebase";
import { validateEmail } from "../../helpers";

type LoginProps = {
  handleToggle: () => void;
  handleClose: () => void;
};

const LoginForm = ({ handleClose, handleToggle }: LoginProps) => {
  const { t } = useTranslation();

  const [email, setEmail]: [string, any] = useState("");
  const [password, setPassword]: [string, any] = useState("");
  const [isEmailValid, setIsEmailValid] = React.useState(true);
  const [isPasswordValid, setIsPasswordValid] = React.useState(true);
  const [errorMsg, setErrorMsg] = useState(() =>
    t("Please enter email and password")
  );
  const [showError, setShowError] = React.useState(false);
  const [showBackdrop, setShowBackdrop] = React.useState(false);

  const handleLoginPressed = () => {
    if (!isEmailValid || email === "" || !isPasswordValid || password === "") {
      setShowError(true);
      return;
    }

    setShowBackdrop(true);

    auth
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        console.log("Successfully Logged in", res);
        handleClose();
      })
      .catch(function (error) {
        // Handle Errors here.
        const errorCode = error.code;
        let message = "";
        switch (errorCode) {
          case "auth/invalid-email":
            message = t("Email address is invalid");
            break;
          case "auth/user-disabled":
            message = t("This user has been disabled");
            break;
          case "auth/user-not-found":
            message = t("Email address not found");
            break;
          case "auth/wrong-password":
            message = t("Incorrect password");
            break;
          default:
            break;
        }
        setShowError(true);
        setErrorMsg(message);
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const validatePassword = (value: string) => {
    return value.length > 6;
  };

  const handleSnackClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setShowError(false);
    setErrorMsg(t("Please enter email and password"));
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">
        {t("Log In to Your Account")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box display="flex">
            Don't have an account?
            <Link
              color="primary"
              onClick={handleToggle}
              style={{ margin: "0 0.25rem" }}
            >
              Sign up!
            </Link>
          </Box>
        </DialogContentText>
        <form noValidate>
          <TextField
            required
            margin="normal"
            fullWidth
            id="email"
            autoComplete="email"
            label={t("Email Address")}
            variant="outlined"
            style={{ width: "100%" }}
            color="secondary"
            onChange={(event) => {
              setEmail(event.target.value);
              setIsEmailValid(validateEmail(event.target.value));
            }}
            error={!isEmailValid}
          />
          <TextField
            required
            margin="normal"
            fullWidth
            id="password"
            label={t("Password")}
            type="password"
            variant="outlined"
            style={{ width: "100%" }}
            color="secondary"
            onChange={(event) => {
              setPassword(event.target.value);
              setIsPasswordValid(validatePassword(event.target.value));
            }}
            error={!isPasswordValid}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleLoginPressed}
          color="primary"
          variant="contained"
        >
          {t("Login")}
        </Button>
      </DialogActions>
      <Backdrop open={showBackdrop} style={{ zIndex: 10 }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={showError}
        autoHideDuration={5000}
        onClose={handleSnackClose}
      >
        <Alert severity="error" variant="filled">
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LoginForm;
