import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import MessageIcon from "@material-ui/icons/Message";
import { useSelector } from "react-redux";

import { RootState } from "../../store";
import { Listing } from "../../store/types";
import { db } from "../../firebase";
import { validateEmail, validatePhone } from "../../helpers";
import { Message } from "../../newTypes";
import { createMessage } from "../../api/MessageAPI";

type ContactDialogProps = {
  listing: Listing;
  id: string;
};

export default function ContactDialogButton({
  listing,
  id,
}: ContactDialogProps) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [showSucess, setShowSuccess] = React.useState(false);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("I'm interested!");
  const [isEmailValid, setIsEmailValid] = React.useState(true);
  const [isPhoneValid, setIsPhoneValid] = React.useState(true);
  const user = useSelector((state: RootState) => state.userReducer.user);

  React.useEffect(() => {
    //If there is a user logged in, fill inputs with user information
    if (user !== undefined) {
      setEmail(user.Email);
      setName(user.Name !== undefined ? user.Name : name);
      setPhone(user.Phone !== undefined ? user.Phone : phone);
    }
  }, [user]);

  const handleSnackClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorMsg("");
    setShowError(false);
    setShowSuccess(false);
  };

  const validateForm = () => {
    let valid = true;
    if (name === "") {
      setErrorMsg("Name is required");
      valid = false;
    } else if (phone === "" && email === "") {
      setErrorMsg("Email or phone is required");
      valid = false;
    } else if (message === "") {
      setErrorMsg("A message is required");
      valid = false;
    } else if (phone !== "" && !isPhoneValid) {
      setErrorMsg("Phone is invalid");
      valid = false;
    } else if (email !== "" && !isEmailValid) {
      setErrorMsg("Email is invalid");
      valid = false;
    }

    if (!valid) {
      setShowError(true);
    }

    return valid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    //TODO: Property_ID should be listing Id
    let newMessage: Message = {
      contact_email: email,
      contact_name: name,
      contact_phone: phone,
      message: {
        subject: `New Message from ${name} on VVIPLiving.com`,
        html: message,
      },
      listing_id: id,
      property_address: listing.Property.Address,
      property_id: listing.Property.Property_ID,
      unit_number: listing.Property.UnitNum,
      type: "Buyer",
    };

    setShowBackdrop(true);
    const success = await createMessage(newMessage)
      .catch((e) => console.log(e))
      .finally(() => {
        setOpen(false);
        setShowBackdrop(false);
      });
    if (success) {
      setShowSuccess(true);
    }

    // //Create BuyerLead Object
    // const buyerLead = {
    //   Contact_Name: name,
    //   Contact_Phone: phone,
    //   Conact_Email: email,
    //   Leads_Enter_Date: new Date(),
    //   Message: message,
    //   Property_Address: listing.Property.Address,
    //   Property_Id: listing.Property.Property_ID,
    //   UnitNum: listing.Property.UnitNum,
    // };
    // setShowBackdrop(true);

    // db.collection("Buyer_Leads")
    //   .add(buyerLead)
    //   .then(function (docRef) {
    //     setOpen(false);
    //     setShowSuccess(true);
    //   })
    //   .catch(function (error) {
    //     console.error("ERROR: creating Buyer Lead ", error);
    //   })
    //   .finally(() => {
    //     setShowBackdrop(false);
    //   });
  };

  return (
    <>
      <Button
        variant="contained"
        size="large"
        color="primary"
        startIcon={<MessageIcon />}
        onClick={() => setOpen(true)}
        style={{ height: "4rem", width: "10rem", fontSize: "1.1rem" }}
      >
        {t("Inquire")}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Inquiry</DialogTitle>
        <DialogContent>
          <form noValidate>
            <TextField
              color="secondary"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="name"
              label={t("Name")}
              id="name"
              autoComplete="name"
              defaultValue={name}
              onChange={(event) => setName(event.target.value)}
            />
            <TextField
              color="secondary"
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label={t("Email Address")}
              name="email"
              autoComplete="email"
              defaultValue={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setIsEmailValid(validateEmail(event.target.value));
              }}
              error={!isEmailValid}
            />
            <TextField
              color="secondary"
              variant="outlined"
              margin="normal"
              fullWidth
              name="phone"
              label={t("Phone")}
              id="phone"
              autoComplete="tel"
              defaultValue={phone}
              onChange={(event) => {
                setPhone(event.target.value);
                setIsPhoneValid(validatePhone(event.target.value));
              }}
              error={!isPhoneValid}
            />
            <TextField
              color="secondary"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="message"
              label={t("Message")}
              id="message"
              autoComplete="message"
              defaultValue={message}
              multiline
              rows={5}
              onChange={(event) => setMessage(event.target.value)}
            />
            <Box
              display="flex"
              justifyContent="center"
              style={{ marginBottom: "1rem" }}
            >
              <Button
                variant="contained"
                size="large"
                color="primary"
                startIcon={<MessageIcon />}
                onClick={handleSubmit}
              >
                {t("Send Inquiry")}
              </Button>
            </Box>
          </form>
        </DialogContent>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={showError}
          autoHideDuration={6000}
          onClose={handleSnackClose}
        >
          <Alert severity="error" variant="filled">
            {errorMsg}
          </Alert>
        </Snackbar>
        <Backdrop open={showBackdrop} style={{ zIndex: 10 }}>
          <CircularProgress color="primary" />
        </Backdrop>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={showSucess}
        autoHideDuration={5000}
        onClose={handleSnackClose}
      >
        <Alert severity="success" variant="filled">
          {t("Inquiry Sent!")}
        </Alert>
      </Snackbar>
    </>
  );
}
