import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
// import Image from "material-ui-image";

import "./Listing.css";
import { ImageType } from "../../store/types";

type ListingCarouselProps = {
  images: ImageType;
};

const ListingCarousel = ({ images }: ListingCarouselProps) => {
  const classes = useStyles();
  const [imageNames, setImageNames]: [Array<string>, any] = React.useState([]);

  React.useEffect(() => {
    //Sort Images by Key Name, so the "Cover" image is first
    let sortableImages: Array<{ key: string; value: string }> = [];
    for (let [key, value] of Object.entries(images)) {
      sortableImages.push({ key, value });
    }
    sortableImages.sort(function (a, b) {
      return a["key"].localeCompare(b["key"]);
    });

    //Set imageNames to the ImageType url value
    let tempImageNames: Array<string> = [];
    sortableImages.forEach((imageObj) => tempImageNames.push(imageObj.value));
    setImageNames(tempImageNames);
  }, [images]);

  return (
    <Carousel
      dynamicHeight={false}
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
      autoPlay={true}
      interval={10000}
      stopOnHover={true}
      className={classes.rootContainer}
    >
      {imageNames.map((imageName) => (
        <div key={imageName} className={classes.imageContainer}>
          <img
            src={imageName === "" ? "/image-not-found.png" : imageName}
            className={classes.image}
            alt={imageName}
          />
        </div>
      ))}
    </Carousel>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      display: "inline-flex",
      borderRadius: 2,
      border: "1px solid #eaeaea",
      marginBottom: 8,
      marginRight: 8,
      width: "100%",
      maxWidth: "100%",
      height: "25rem",
      padding: 4,
      boxSizing: "border-box",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      overflow: "hidden",
    },
    imageContainer: {
      display: "flex",
      minWidth: 0,
      overflow: "hidden",
      height: "25rem",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
    },
    image: {
      display: "block",
      width: "auto",
      height: "100%",
      objectFit: "contain",
    },
    flexBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.5rem",
    },
  })
);

export default ListingCarousel;
