import geohash from "ngeohash";
import { TimeStamp } from "./newTypes";

export const getDate = (date?: TimeStamp) => {
  if (date === undefined) {
    //TODO: Return something better than todays date
    return new Date();
  }
  return new Date(date.seconds * 1000);
};

export const format = (num?: number) => {
  if (num === undefined) {
    return "$???";
  }
  return "$" + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const shortPrice = (num: number) => {
  if (num > 999999) {
    return "$" + (num / 1000000).toFixed(2) + "m";
  } else if (num > 999) {
    return "$" + num / 1000 + "k";
  } else {
    return format(num);
  }
};

// Calculate the upper and lower boundary geohashes for
// a given latitude, longitude, and distance in miles
export const getGeohashRange = (
  latitude: number,
  longitude: number,
  distance: number
) => {
  const lat = 0.0144927536231884; // degrees latitude per mile
  const lon = 0.0181818181818182; // degrees longitude per mile

  const lowerLat = latitude - lat * distance;
  const lowerLon = longitude - lon * distance;

  const upperLat = latitude + lat * distance;
  const upperLon = longitude + lon * distance;

  const lower = geohash.encode(lowerLat, lowerLon);
  const upper = geohash.encode(upperLat, upperLon);

  return {
    lower,
    upper,
  };
};

//Return distance in Kilometers
export const distance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    //Return in kilometers
    dist = dist * 1.609344;

    return dist;
  }
};

export const validateEmail = (value: string) => {
  const emailTest = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
  return emailTest.test(value);
};

export const validatePhone = (value: string) => {
  const tenNum = /^\d{10}$/;
  const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const res = phoneno.test(value) || tenNum.test(value);
  return res;
};
