import React from "react";
import { Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { db } from "../firebase";
import ListingList from "../components/listing/ListingList";
import { Listing } from "../store/types";
import VIPListing from "../components/listing/VIPListing";

const MainPage = () => {
  const { t } = useTranslation();

  const [featuredListings, setFeaturedListings]: [
    Array<Listing>,
    any
  ] = React.useState([]);
  const [saleListings, setSaleListings]: [Array<Listing>, any] = React.useState(
    []
  );
  const [newListings, setNewListings]: [Array<Listing>, any] = React.useState(
    []
  );
  const [isLoadingSales, setIsLoadingSales] = React.useState(true);
  const [isLoadingNew, setIsLoadingNew] = React.useState(true);

  React.useEffect(() => {
    //Get Featured Listings
    db.collection("Listings")
      .where("Listing_Priority_Type", "==", "101")
      .orderBy("Create_Date", "desc")
      .limit(5)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          const listing = doc.data();
          listing.id = doc.id;
          return listing;
        });
        setFeaturedListings(data);
      })
      .catch((err) => console.log(err));

    db.collection("Listings")
      .where("Project.Construction_State_Type", "==", "104")
      // .where("Listing_Priority_Type", ">", "101")
      .where("Listing_Priority_Type", "<", "101")
      .limit(10)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          const listing = doc.data();
          listing.id = doc.id;
          return listing;
        });
        //Sort by date
        data.sort(function (a, b) {
          return b.Create_Date.seconds - a.Create_Date.seconds;
        });
        setSaleListings(data);
        setIsLoadingSales(false);
      })
      .catch((err) => console.log(err));

    db.collection("Listings")
      .where("Project.Construction_State_Type", "<", "104")
      .limit(10)
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map((doc) => {
          const listing = doc.data();
          listing.id = doc.id;
          return listing;
        });
        //Filter out Priority == 101
        data = data.filter((listing) => listing.Listing_Priority_Type < 101);
        //Sort by date
        data.sort(function (a, b) {
          return b.Create_Date.seconds - a.Create_Date.seconds;
        });
        setNewListings(data);
        setIsLoadingNew(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <VIPListing listings={featuredListings} />
      <Container maxWidth="xl">
        <ListingList
          title={t("Assignment Sale")}
          isLoading={isLoadingSales}
          listings={saleListings}
        />
        <ListingList
          title={t("New Project")}
          isLoading={isLoadingNew}
          listings={newListings}
        />
      </Container>
    </>
  );
};

export default MainPage;
