import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Divider,
  Paper,
  Typography,
  Box,
  Hidden,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {
  KingBed,
  Bathtub,
  SquareFoot,
  LocalParking,
  Lock,
  StarBorder,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import { Listing } from "../../store/types";
import { format } from "../../helpers";

type BrowseItemProps = {
  listing: Listing;
  constructionState: string;
};

const BrowseItem = ({ listing, constructionState }: BrowseItemProps) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push("/listing/" + listing.id);
  };

  return (
    <Paper elevation={5} style={{ margin: "1rem 0" }}>
      <Card className={classes.cardContainer}>
        <CardActionArea onClick={handleClick} style={{ display: "flex" }}>
          <Hidden xsDown>
            <div className={classes.rootContainer}>
              <div className={classes.imageContainer}>
                {listing.Images === undefined ? (
                  <Skeleton variant="rect" width={"180px"} height={"180px"} />
                ) : (
                  <img
                    className={classes.image}
                    src={
                      listing.Images?.Cover_Image === ""
                        ? "error"
                        : listing.Images?.Cover_Image
                    }
                    alt={listing.Project.Project_Name}
                  />
                )}
              </div>
            </div>
          </Hidden>
          <CardContent className={classes.content}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Typography variant="h5">
                  {listing.Project?.Project_Name}
                </Typography>
              </Box>

              <Typography gutterBottom variant="h5">
                {format(listing.Listing_Price)}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle1" color="textSecondary">
                {listing.Property.Address}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {constructionState}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              color="textSecondary"
              gutterBottom
              style={{ minHeight: "1rem" }}
            >
              {listing.Description}
            </Typography>
            <Divider style={{ marginBottom: "1rem" }} />
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              {listing.Listing_Priority_Type === "101" && (
                <>
                  <Hidden xsDown>
                    <Chip
                      className={classes.chip}
                      variant="outlined"
                      icon={<StarBorder />}
                      label="Featured"
                      color="primary"
                    />
                  </Hidden>
                  <Hidden smUp>
                    <StarBorder color="primary" className={classes.chip} />
                  </Hidden>
                </>
              )}
              <Chip
                className={classes.chip}
                variant="outlined"
                icon={<KingBed />}
                label={listing.Property.Bedrooms}
                color="secondary"
              />
              <Chip
                className={classes.chip}
                variant="outlined"
                icon={<Bathtub />}
                label={listing.Property.Washrooms}
                color="secondary"
              />
              <Chip
                className={classes.chip}
                variant="outlined"
                icon={<SquareFoot />}
                label={listing.Property.Property_SQFT}
                color="secondary"
              />
              {listing.Property?.Parking && (
                <LocalParking className={classes.chip} color="secondary" />
              )}
              {listing.Property?.Locker && (
                <Lock className={classes.chip} color="secondary" />
              )}
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Paper>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    cardContainer: { minHeight: "12rem", display: "flex" },
    chip: { margin: "0 0.5rem" },
    content: {
      zIndex: 1,
      // backgroundColor: "white",
      minHeight: "12rem",
      width: "100%",
    },
    rootContainer: {
      display: "inline-flex",
      borderRadius: 2,
      border: "1px solid #eaeaea",
      // marginBottom: 8,
      // marginRight: 8,
      width: "12rem",
      maxWidth: "100%",
      height: "12rem",
      // padding: 4,
      boxSizing: "border-box",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      overflow: "hidden",
    },
    imageContainer: {
      display: "flex",
      minWidth: 0,
      overflow: "hidden",
      justifyContent: "center",
      maxWidth: "12rem",
    },
    image: {
      display: "block",
      width: "auto",
      height: "100%",
      objectFit: "cover",
    },
  })
);

export default BrowseItem;
