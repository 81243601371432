import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  TextField,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { auth, db } from "../../firebase";
import { validatePhone, validateEmail } from "../../helpers";
import { User } from "../../store/types";

type RegisterProps = {
  handleToggle: () => void;
  handleClose: () => void;
};

const RegisterForm = ({ handleClose, handleToggle }: RegisterProps) => {
  const { t } = useTranslation();

  const [name, setName]: [string, any] = useState("");
  const [email, setEmail]: [string, any] = useState("");
  const [phone, setPhone]: [string, any] = useState("");
  const [password, setPassword]: [string, any] = useState("");
  const [isEmailValid, setIsEmailValid] = React.useState(true);
  const [isPhoneValid, setIsPhoneValid] = React.useState(true);
  const [isPasswordValid, setIsPasswordValid] = React.useState(true);
  const [showError, setShowError] = React.useState(false);
  const [errorMsg, setErrorMsg] = useState(() =>
    t("Please enter a valid email and password")
  );
  const [showBackdrop, setShowBackdrop] = React.useState(false);

  const handleRegister = () => {
    if (
      !isEmailValid ||
      email === "" ||
      !isPasswordValid ||
      password === "" ||
      name === ""
    ) {
      setShowError(true);
      return;
    }

    setShowBackdrop(true);

    const user: User = {
      Name: name,
      Email: email,
      Phone: phone,
    };

    //Create Authentication User
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        console.log("Created Auth User:", res);
        console.log("Local User:", user);
        //Update newly created user's display name
        if (res.user) {
          res.user
            .updateProfile({
              displayName: user.Name,
            })
            .then(function () {
              // Update successful.
              console.log("Update DisplayName Sucessful");
            })
            .catch(function (error) {
              // An error happened.
              console.log("Update DisplayName Error");
            })
            .finally(() => {
              handleClose();
            });
        } else {
          console.log("ERROR: NO USER DATA");
        }
      })
      .catch((error) => {
        console.log(error.message);
        const errorCode = error.code;
        let message = "";
        switch (errorCode) {
          case "auth/invalid-email":
            message = t("Email address is invalid");
            break;
          case "auth/email-already-in-use":
            message = t("An account with this email already exists");
            break;
          case "auth/operation-not-allowed":
            message = t("Email and password accounts are disabled");
            break;
          case "auth/weak-password":
            message = t("Password is too weak");
            break;
          default:
            break;
        }
        setShowError(true);
        setErrorMsg(message);
      })
      .finally(() => {
        setShowBackdrop(false);
      });

    //Save User Information in DB
    db.collection("Users")
      .add(user)
      .then(function (docRef) {
        console.log("User created with ID: ", docRef.id);
      })
      .catch(function (error) {
        console.error("ERROR: Creating User", error);
      });
  };

  const validatePassword = (value: string) => {
    return value.length > 6;
  };

  const handleSnackClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setShowError(false);
    setErrorMsg("Please enter a valid email and password");
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">
        {t("Create Your Account")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box display="flex">
            Already have an account?
            <Link
              color="primary"
              onClick={handleToggle}
              style={{ marginLeft: "0.25rem" }}
            >
              Login!
            </Link>
          </Box>
        </DialogContentText>
        <form noValidate>
          <TextField
            required
            margin="normal"
            fullWidth
            id="name"
            autoComplete="name"
            label={t("Name")}
            variant="outlined"
            style={{ width: "100%" }}
            color="secondary"
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            required
            margin="normal"
            fullWidth
            id="email"
            autoComplete="email"
            label={t("Email Address")}
            variant="outlined"
            style={{ width: "100%" }}
            color="secondary"
            onChange={(event) => {
              setEmail(event.target.value);
              setIsEmailValid(validateEmail(event.target.value));
            }}
            error={!isEmailValid}
          />
          <TextField
            margin="normal"
            fullWidth
            id="phone"
            autoComplete="tel"
            label={t("Phone")}
            variant="outlined"
            style={{ width: "100%" }}
            color="secondary"
            onChange={(event) => {
              setPhone(event.target.value);
              setIsPhoneValid(validatePhone(event.target.value));
            }}
            error={!isPhoneValid}
          />
          <TextField
            required
            margin="normal"
            fullWidth
            id="password"
            label={t("Password")}
            type="password"
            variant="outlined"
            style={{ width: "100%" }}
            color="secondary"
            onChange={(event) => {
              setPassword(event.target.value);
              setIsPasswordValid(validatePassword(event.target.value));
            }}
            error={!isPasswordValid}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRegister} color="primary" variant="contained">
          {t("Sign Up")}
        </Button>
      </DialogActions>
      <Backdrop open={showBackdrop} style={{ zIndex: 10 }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={showError}
        autoHideDuration={5000}
        onClose={handleSnackClose}
      >
        <Alert severity="error" variant="filled">
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RegisterForm;
