import React from "react";
import { Switch, Route } from "react-router-dom";
import { CssBaseline, Hidden } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { useDispatch } from "react-redux";

import Navigation from "../components/Navigation";
import MainPage from "./MainPage";
import MapPage from "./MapPage";
import BrowsePage from "./BrowsePage";
import ListingPage from "./ListingPage";
import ContactPage from "./ContactPage";
import AboutPage from "./AboutPage";
import PrivacyPage from "./PrivacyPage";
import Footer from "../components/appbars/FooterBar";
import { auth, db } from "../firebase";
import { login } from "../store/user/actions";
import { User } from "../store/types";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#F44336",
      main: "#F44336",
      dark: "#F44336",
      contrastText: "#fff",
    },
    secondary: {
      light: grey[400],
      main: grey[500],
      dark: grey[600],
      contrastText: "#000",
    },
  },
});

function App() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    auth.onAuthStateChanged(function (authUser) {
      if (authUser) {
        //User is signed in
        if (authUser.email) {
          handleSignIn(authUser.email);
        } else {
          console.log("ERROR: Missing Email");
        }
      } else {
        // User is signed out.
        const newUserState = {
          isLoggedIn: false,
          user: undefined,
        };
        dispatch(login(newUserState));
      }
    });
  }, []);

  const handleSignIn = (email: string) => {
    //GET USER DATA
    db.collection("Users")
      .where("Email", "==", email)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          return doc.data();
        });
        if (data.length > 0) {
          const userData = data[0];
          const user: User = {
            Email: userData.Email ? userData.Email : "",
            Name: userData.Name ? userData.Name : "",
            Phone: userData.Phone ? userData.Phone : "",
          };
          const newUserState = {
            isLoggedIn: true,
            user: user,
          };
          dispatch(login(newUserState));
        } else {
          console.log("ERROR: No user information found");
        }
      })
      .catch((err) => console.log("ERROR: Couldn't get User", err));
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navigation />
      <div style={{ minHeight: "90vh" }}>
        <Switch>
          <Route path="/map" render={(props) => <MapPage />} />
          <Route path="/browse" render={(props) => <BrowsePage />} />
          <Route path="/listing/:id" render={(props) => <ListingPage />} />
          <Route path="/contact" render={(props) => <ContactPage />} />
          <Route path="/listmyproperty" render={(props) => <ContactPage />} />
          <Route path="/about" render={(props) => <AboutPage />} />
          <Route path="/privacy" render={(props) => <PrivacyPage />} />
          <Route path="/" render={(props) => <MainPage />} />
        </Switch>
      </div>
      <Hidden xsDown>
        <Footer />
      </Hidden>
    </ThemeProvider>
  );
}

export default App;
