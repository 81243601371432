import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Typography,
  Box,
  CardActionArea,
  Collapse,
} from "@material-ui/core";
import {
  KingBed,
  Bathtub,
  SquareFoot,
  LocalParking,
  Lock,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import { Listing } from "../../store/types";
import { format } from "../../helpers";

type SideBarItemProps = {
  listing: Listing;
  isSelected: boolean;
  handleSelect: any;
  handleHover: any;
  constructionState: string;
};

const SideBarItem = ({
  listing,
  isSelected,
  handleSelect,
  handleHover,
  constructionState,
}: SideBarItemProps) => {
  const history = useHistory();
  const [checked, setChecked] = React.useState(false);

  const handleClick = () => {
    history.push("/listing/" + listing.id);
  };

  const handleCardClick = () => {
    handleSelect(isSelected ? undefined : listing);
    setChecked(!checked);
  };

  return (
    <Card elevation={5} style={{ margin: "1rem" }}>
      <CardActionArea
        onClick={handleCardClick}
        onMouseEnter={() => handleHover(listing)}
        onMouseLeave={() => handleHover(undefined)}
      >
        {listing.Images?.Cover_Image === "" ? (
          <CardMedia
            component="img"
            alt="Cover Image"
            height={150}
            image={"/image-not-found.png"}
            title="Cover Image"
          />
        ) : (
          <CardMedia
            component="img"
            alt="Cover Image"
            height={150}
            image={listing.Images?.Cover_Image}
            title="Cover Image"
          />
        )}
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5">
              {format(listing.Listing_Price)}
            </Typography>
            <Button variant="outlined" color="secondary" onClick={handleClick}>
              Details
            </Button>
          </Box>
          <Collapse in={isSelected} style={{ marginTop: "1rem" }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle1">
                {listing.Project.Project_Name}
              </Typography>
              <Typography variant="subtitle1">{constructionState}</Typography>
            </Box>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {listing.Description}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {listing.Note}
            </Typography>
          </Collapse>
          <Typography variant="body2" color="textSecondary">
            {listing.Property.Address}
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: "1rem" }}
          >
            <Chip
              variant="outlined"
              icon={<KingBed />}
              label={listing.Property.Bedrooms}
              color="secondary"
            />
            <Chip
              variant="outlined"
              icon={<Bathtub />}
              label={listing.Property.Washrooms}
              color="secondary"
            />
            <Chip
              variant="outlined"
              icon={<SquareFoot />}
              label={listing.Property.Property_SQFT}
              color="secondary"
            />
            {listing.Property.Parking && <LocalParking color="secondary" />}
            {listing.Property.Locker && <Lock color="secondary" />}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default SideBarItem;
