import React from "react";
//import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Container } from "@material-ui/core";

const PrivacyPage = () => {
  const classes = useStyles();
  //const { t } = useTranslation();

  return (
    <Container maxWidth="md">
      <Box className={classes.centerTitle}>
        <img
          src="/vip_logo.png"
          alt="VIP Assignment logo"
          width={45}
          height={45}
        />
        <Typography
          variant="h4"
          style={{ textAlign: "center", margin: "3rem 0.5rem" }}
          gutterBottom
        >
          Privacy
        </Typography>
      </Box>

      <Typography variant="body1" gutterBottom>
        Protecting the customer’s privacy is crucial to our business. We abide
        by the privacy laws in the countries where we do business. The Privacy
        Statement outlines our personal information handling practices.
      </Typography>
      <Typography variant="body1" gutterBottom>
        VIPCONDO collects and uses user’s data to provide individual users the
        Service, to improve our products and services, to communicate with users
        about offers that may be of interest, and for other administrative
        purposes.
      </Typography>
      <Typography variant="body1" gutterBottom>
        VIPCONDO collects certain information which can directly or indirectly
        identify you (“Personal Data”) for the purposes described below. We will
        use such information only as described in this Privacy Statement and/or
        in the way we specify at the time of collection. We will not
        subsequently change the way personal data is used without user’s
        consent, as required by applicable law.
      </Typography>
      <Typography variant="body1" gutterBottom>
        VIPCONDO requires your name and email to provide you the Service,
        including allowing you to create an account, view protected content,
        provide feedback and allow you to receive support (including
        communicating with you via email).
      </Typography>
      <Typography variant="body1" gutterBottom>
        In addition to your name, email, and phone, we may collect other
        information about your use of our Service and certain technical details
        that your browser sends to our website. We use standard automated tools,
        which include Web beacons, cookies, embedded Web links, and other
        commonly used information-gathering tools to collect this information.
      </Typography>
      <Typography variant="body1" gutterBottom>
        We collect anonymized data sets in order to understand Service usage,
        adoption, and virality, which will then be used to improve the Service.
      </Typography>
    </Container>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    centerTitle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

export default PrivacyPage;
