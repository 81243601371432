import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Slider,
  IconButton,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import TuneIcon from "@material-ui/icons/Tune";
import { useLoadScript } from "@react-google-maps/api";

import { db } from "../../firebase";
import PlaceSearch from "../map/PlaceSearch";
import { Project, ListOption, FilterArgs } from "../../store/types";
import { shortPrice } from "../../helpers";

const libraries = ["places"];

interface Props {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

type SearchProps = {
  handleFilter: (filterArgs: FilterArgs) => void;
  handleOptionSelect: (option: ListOption) => void;
};

const Search = function ({ handleFilter, handleOptionSelect }: SearchProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [projects, setProjects]: [Array<Project>, any?] = useState([]);
  const [isHidden, setIsHidden] = useState(true);
  const [value, setValue] = useState<number[]>([0, 30]);
  const [bedrooms, setBedrooms] = useState(1);
  const [bathrooms, setBathrooms] = useState(1);
  const [occupancy, setOccupancy] = useState(5);
  const [hasParking, setHasParking] = useState(false);
  //Load Google Maps
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  //Marks for Price Slider
  const marks = [
    { value: 0, label: "$0" },
    // { value: 5, label: "$500,000" },
    { value: 10, label: "$1,000,000" },
    // { value: 15, label: "$1,500,000" },
    { value: 20, label: "$2,000,000" },
    // { value: 25, label: "$2,500,000" },
    { value: 30, label: "$3,000,000+" },
  ];

  React.useEffect(() => {
    //GET PROJECTS
    db.collection("Projects")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          const tempProject = doc.data();
          tempProject.Project_ID = doc.id;
          return tempProject;
        });
        setProjects(data);
      })
      .catch((err) => console.log("ERROR: Couldn't get Projects", err));
  }, []);

  //React to Changes in Form and call handleFilter with new values
  React.useEffect(() => {
    const min = value[0] * 100000;
    const max = value[1] * 100000;
    const filterObj: FilterArgs = {
      min,
      max,
      beds: bedrooms,
      baths: bathrooms,
      occupancy,
      hasParking,
    };
    //TODO: Add RxJs to handleFilter Call
    handleFilter(filterObj);
  }, [value, bedrooms, bathrooms, occupancy, hasParking]);

  const handleTune = () => {
    setIsHidden(!isHidden);
  };

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const handleBedrooms = (
    event: React.MouseEvent<HTMLElement>,
    newBedrooms: number
  ) => {
    setBedrooms(newBedrooms);
  };

  const handleBathrooms = (
    event: React.MouseEvent<HTMLElement>,
    newBathrooms: number
  ) => {
    setBathrooms(newBathrooms);
  };

  const handleOccupancy = (
    event: React.MouseEvent<HTMLElement>,
    newOccupancy: number
  ) => {
    setOccupancy(newOccupancy);
  };

  const valuetext = () => {
    return `${value}M`;
  };

  const handleSwitchChange = () => {
    setHasParking(!hasParking);
  };

  if (loadError) return <p>Error Loading Places API</p>;

  const ValueLabelComponent = (props: Props) => {
    const { children, open, value } = props;

    return (
      <Tooltip
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={value}
        arrow
      >
        {children}
      </Tooltip>
    );
  };

  return (
    <div className={classes.searchContainer}>
      <Box display="flex" flexGrow={1} justifyContent="center">
        {isLoaded && projects.length > 0 ? (
          <PlaceSearch
            projects={projects}
            handleOptionSelect={handleOptionSelect}
          />
        ) : (
          <TextField
            id="outlined-basic"
            label={t("Project Name or Address")}
            variant="outlined"
            color="secondary"
            disabled={true}
            style={{ width: "100%", backgroundColor: "white" }}
          />
        )}
        <IconButton aria-label="advances" onClick={handleTune}>
          <TuneIcon fontSize="large" />
        </IconButton>
      </Box>
      <Grid
        container
        spacing={3}
        style={{ display: isHidden ? "none" : "flex", marginTop: "1rem" }}
      >
        <Grid item xs={11} style={{ margin: "0 2rem" }}>
          <Typography variant="subtitle1" color="textSecondary">
            Price Range
          </Typography>
          <Slider
            value={value}
            min={0}
            max={30}
            step={1}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            getAriaValueText={valuetext}
            marks={marks}
            valueLabelFormat={(num) => shortPrice(num * 100000)}
            ValueLabelComponent={ValueLabelComponent}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="subtitle1" color="textSecondary">
            {t("Bedrooms")}
          </Typography>
          <ToggleButtonGroup
            size="medium"
            value={bedrooms}
            exclusive
            style={{ width: "100%" }}
            onChange={handleBedrooms}
          >
            <ToggleButton value={1} style={{ width: "24%" }}>
              1+
            </ToggleButton>
            <ToggleButton value={2} style={{ width: "24%" }}>
              2+
            </ToggleButton>
            <ToggleButton value={3} style={{ width: "24%" }}>
              3+
            </ToggleButton>
            <ToggleButton value={4} style={{ width: "24%" }}>
              4+
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="subtitle1" color="textSecondary">
            {t("Bathrooms")}
          </Typography>
          <ToggleButtonGroup
            size="medium"
            value={bathrooms}
            exclusive
            style={{ width: "100%" }}
            onChange={handleBathrooms}
          >
            <ToggleButton value={1} style={{ width: "24%" }}>
              1+
            </ToggleButton>
            <ToggleButton value={2} style={{ width: "24%" }}>
              2+
            </ToggleButton>
            <ToggleButton value={3} style={{ width: "24%" }}>
              3+
            </ToggleButton>
            <ToggleButton value={4} style={{ width: "24%" }}>
              4+
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="subtitle1" color="textSecondary">
            {t("Occupancy In Years")}
          </Typography>
          <ToggleButtonGroup
            size="medium"
            value={occupancy}
            exclusive
            style={{ width: "100%" }}
            onChange={handleOccupancy}
          >
            <ToggleButton value={1} style={{ width: "20%" }}>
              1+
            </ToggleButton>
            <ToggleButton value={2} style={{ width: "20%" }}>
              2+
            </ToggleButton>
            <ToggleButton value={3} style={{ width: "20%" }}>
              3+
            </ToggleButton>
            <ToggleButton value={4} style={{ width: "20%" }}>
              4+
            </ToggleButton>
            <ToggleButton value={5} style={{ width: "20%" }}>
              Any
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <FormControlLabel
              className={"MuiTypography-colorTextSecondary"}
              control={
                <Switch
                  checked={hasParking}
                  onChange={handleSwitchChange}
                  color="primary"
                />
              }
              label={t("Has Parking")}
              labelPlacement="top"
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchContainer: {
      padding: "2rem",
      [theme.breakpoints.down("xs")]: {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
  })
);

export default Search;
