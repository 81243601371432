import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ENTranslations from "./locales/en/translation.json";
import ZHTranslations from "./locales/zh/translation.json";

const resources = {
  en: {
    translation: ENTranslations,
  },
  zh: {
    translation: ZHTranslations,
  },
};

const detectorOptions = {
  // order and from where user language should be detected
  order: [
    "navigator",
    "querystring",
    "cookie",
    "localStorage",
    "sessionStorage",
    "htmlTag",
    "path",
    "subdomain",
  ],
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    debug: false,
    detection: detectorOptions,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
