import React from "react";
import { Hidden } from "@material-ui/core";

import NavBar from "./appbars/NavBar";
import SideDrawer from "./appbars/SideDrawer";

function Navigation() {
  return (
    <div>
      <Hidden xsDown>
        <NavBar />
      </Hidden>
      <Hidden smUp>
        <SideDrawer />
      </Hidden>
    </div>
  );
}

export default Navigation;
