import React from "react";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AppBar, Box, Button, Toolbar, Typography } from "@material-ui/core";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { useHistory } from "react-router-dom";

const FooterBar = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const handleListProperty = () => {
    //Scroll to top of page
    window.scrollTo(0, 0);
    history.push("/listmyproperty");
  };

  const handleAboutUs = () => {
    //Scroll to top of page
    window.scrollTo(0, 0);
    history.push("/about");
  };

  const handlePrivacy = () => {
    //Scroll to top of page
    window.scrollTo(0, 0);
    history.push("/privacy");
  };

  return (
    <AppBar position="static" className={classes.bar}>
      <Toolbar>
        <Typography variant="body2">
          {t("© 2020 Copyright VIPAssignment.com")}
        </Typography>
        <Box display="flex" flexGrow={1} justifyContent="flex-end">
          <Button color="inherit" onClick={handlePrivacy}>
            Privacy
          </Button>
          <Button color="inherit" onClick={handleAboutUs}>
            About Us
          </Button>
          <Button color="inherit" onClick={handleListProperty}>
            List My Property
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bar: {
      backgroundColor: blueGrey[500],
      marginTop: "1rem",
    },
  })
);

export default FooterBar;
