import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Listing } from "../../store/types";
import ListingCard from "./ListingCard";

type ListingListProps = {
  title: string;
  isLoading: boolean;
  listings: Array<Listing>;
};

const ListingList = ({ title, isLoading, listings }: ListingListProps) => {
  const classes = useStyles();

  return (
    <div className={classes.conainter}>
      <Typography
        variant="h5"
        component="h2"
        style={{ marginLeft: "1rem", color: "#34515e" }}
        gutterBottom
      >
        {title}
      </Typography>
      <div className={classes.scrollContainer}>
        {isLoading ? (
          [0, 1, 2, 3, 4, 5].map((value) => (
            <Skeleton
              key={value}
              className={classes.scrollItem}
              style={{ margin: "1rem" }}
              variant="rect"
              width={"25rem"}
              height={"20rem"}
            />
          ))
        ) : listings.length === 0 ? (
          <Typography
            variant="h6"
            component="h2"
            style={{ marginLeft: "1rem" }}
            color="textSecondary"
            gutterBottom
          >
            No Listings Found
          </Typography>
        ) : (
          listings.map((listing) => (
            <Box className={classes.scrollItem} key={listing.id}>
              <ListingCard {...listing} />
            </Box>
          ))
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    conainter: {
      margin: "1rem",
    },
    gridList: {
      flexWrap: "nowrap",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
    },
    scrollContainer: {
      overflow: "auto",
      whiteSpace: "nowrap",
    },
    scrollItem: {
      display: "inline-block",
    },
  })
);

export default ListingList;
