import React from "react";
import {
  GoogleMap,
  useLoadScript,
  MarkerClusterer,
} from "@react-google-maps/api";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { getGeocode, getLatLng } from "use-places-autocomplete";

import mapStyles from "./mapStyles";
import ListingPin from "./ListingPin";
import ListingInfoWindow from "./ListingInfoWindow";
import { Listing, Project, Coords, ListOption } from "../../store/types";
import { db } from "../../firebase";
import PlaceSearch from "./PlaceSearch";
import { getGeohashRange, distance } from "../../helpers";

//Map Settings
const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "100%",
};
const center = {
  lat: 43.6536,
  lng: -79.384051,
};
const styles: any = mapStyles;
const options: google.maps.MapOptions = {
  styles: styles,
  disableDefaultUI: true,
  zoomControl: true,
  clickableIcons: false,
  minZoom: 10,
  maxZoom: 20,
};
// "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
const clusterOptions = {
  imagePath: "/cluster",
  gridSize: 40,
  zoomOnClick: false,
};

type VIPMapProps = {
  listings: Array<Listing>;
  barSelected?: Listing;
  handleSelect: any;
  hovered?: Listing;
  handlePlaceSelect: (coords: Coords) => void;
};

const VIPMap = ({
  listings,
  barSelected,
  handleSelect,
  hovered,
  handlePlaceSelect,
}: VIPMapProps) => {
  const classes = useStyles();

  //Map Initialization
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const mapRef = React.useRef<google.maps.Map>();
  const onMapLoad = React.useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  //Properties
  const [constructionStates, setConstructionStates]: [
    Array<string>,
    any?
  ] = React.useState([]);
  const [projects, setProjects]: [Array<Project>, any?] = React.useState([]);
  const [selectedListings, setSelectedListings]: [
    Array<Listing>,
    any?
  ] = React.useState([]);
  const [isInfoOpen, setIsInfoOpen]: [
    boolean | undefined,
    any?
  ] = React.useState(false);
  const [windowPoint, setWindowPoint]: [
    Coords | undefined,
    any?
  ] = React.useState();

  React.useEffect(() => {
    //GET CONSTRUCTION STATE TYPE
    db.collection("Types")
      .doc("Construction_State")
      .get()
      .then((querySnapshot) => {
        const constructionStates = querySnapshot.data();
        setConstructionStates(constructionStates);
      })
      .catch((err) =>
        console.log("ERROR: Couldn't get construction states", err)
      );
    //GET Projects for Search
    db.collection("Projects")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          const tempProject = doc.data();
          tempProject.Project_ID = doc.id;
          return tempProject;
        });
        setProjects(data);
      })
      .catch((err) => console.log("ERROR: Couldn't get Projects", err));
  }, []);

  const panTo = ({ lat, lng }: Coords) => {
    if (mapRef && mapRef.current) {
      //Offset lat
      mapRef.current.panTo({ lat: lat + 0.005, lng });
      mapRef.current.setZoom(15);
    }
  };

  //On SideBar Select
  React.useEffect(() => {
    //Pan to Selected
    if (barSelected !== undefined) {
      handleSingleListingClick(barSelected);
    }
  }, [barSelected]);

  const handleOptionSelect = (option: ListOption) => {
    if (option.project !== undefined) {
      getAddressListings(option.project.Project_Address);
    }

    if (option.place !== undefined) {
      getAddressListings(option.place.description);
    }
  };

  const getAddressListings = async (address: string) => {
    if (address === "") {
      console.log("No address to decode!");
      return;
    }

    try {
      const results = await getGeocode({
        address,
      });
      const { lat, lng } = await getLatLng(results[0]);
      handleAddressSelect(lat, lng);
    } catch (err) {
      console.log("ERROR: Could not get GeoCode", err);
    }
  };

  const handleAddressSelect = (lat: number, lng: number) => {
    panTo({ lat, lng });
    handlePlaceSelect({ lat, lng });
  };

  const handleSingleListingClick = (listing: Listing) => {
    setSelectedListings([listing]);
    setIsInfoOpen(true);
    const lat = listing.Property.Location.latitude;
    const lng = listing.Property.Location.longitude;
    setWindowPoint({ lat, lng });
    panTo({ lat, lng });
  };

  //Clicking on a cluster triggers a search for nearby listings,
  //then refines the radius based on the map zoom
  const handleClusteredClick = (cluster: any) => {
    const center = cluster.getCenter();
    const lat = center.lat();
    const lng = center.lng();

    //Set the radius to filter by based on the map Zoom
    let radius = 0.1;
    if (mapRef && mapRef.current) {
      // Min value of mapRef.current.getZoom() == 10
      // Max value of mapRef.current.getZoom() == 20
      const zoom = Math.max(mapRef.current.getZoom() - 9, 1);
      switch (zoom) {
        case 1:
          radius = 5;
          break;
        case 2:
          radius = 3;
          break;
        case 3:
          radius = 2;
          break;
        case 4:
          radius = 1;
          break;
        case 5:
          radius = 0.5;
          break;
        case 6:
          radius = 0.25;
          break;
        default:
          radius = 0.2;
          break;
      }
    }

    //Get all Listings next to Cluster Marker location
    const searchRadius = 1;
    const range = getGeohashRange(lat, lng, searchRadius);
    db.collection("Listings")
      .where("Property.Geohash", ">=", range.lower)
      .where("Property.Geohash", "<=", range.upper)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          const tempListing = doc.data();
          tempListing.id = doc.id;
          return tempListing;
        });

        //Calculate distance from listing
        //Filter out listings that are too far.
        const result = data.filter((listing) => {
          const lat2 = listing.Property.Location.latitude;
          const lng2 = listing.Property.Location.longitude;
          let dist = distance(lat, lng, lat2, lng2);
          return dist < radius;
        });
        setSelectedListings(result);
        setIsInfoOpen(true);
        setWindowPoint({ lat, lng });
      })
      .catch((err) => console.log("ERROR: Couldn't get nearby Listings", err));
  };

  if (loadError) return <p>Error Loading Maps</p>;
  if (!isLoaded)
    return <Skeleton variant="rect" width={"100%"} height={"100%"} />;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={12}
      center={center}
      onClick={() => handleSelect(undefined)}
      options={options}
      onLoad={onMapLoad}
    >
      <MarkerClusterer
        imageSizes={[56]}
        styles={[
          {
            url: "/cluster-marker.png",
            width: 56,
            height: 56,
            textColor: "white",
            textSize: 14,
          },
        ]}
        options={clusterOptions}
        onClick={handleClusteredClick}
      >
        {(clusterer) =>
          listings.map((listing) => (
            <ListingPin
              key={listing.id}
              listing={listing}
              onListingClick={() => handleSingleListingClick(listing)}
              isHovered={hovered !== undefined && hovered.id === listing.id}
              clusterer={clusterer}
            />
          ))
        }
      </MarkerClusterer>
      {isInfoOpen && windowPoint ? (
        <ListingInfoWindow
          listings={selectedListings}
          point={windowPoint}
          handleClose={() => {
            setIsInfoOpen(false);
            setWindowPoint(undefined);
          }}
          constructionStates={constructionStates}
        />
      ) : null}
      {projects.length > 0 ? (
        <div className={classes.search}>
          <PlaceSearch
            projects={projects}
            handleOptionSelect={handleOptionSelect}
          />
        </div>
      ) : null}
    </GoogleMap>
  );
};

const useStyles = makeStyles(() => ({
  search: {
    position: "absolute",
    top: "0.5rem",
    left: "50%",
    transform: "translateX(-50%)",
    width: "25rem",
    zIndex: 10,
  },
}));

export default VIPMap;
