import { db } from "../firebase";
import { Listing, Property } from "../store/types";
import { getGeohashRange } from "../helpers";

// export const LISTINGS_PAGE_INT = 50;

//TODO: Add filter/constraint to listings
export const fetchListings = async (name?: string) => {
  let startAfterName = "";
  if (name !== undefined) {
    startAfterName = name;
  }

  const listings: Array<Listing> = await db
    .collection("Listings")
    .get()
    .then((querySnapshot) => {
      return querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        } as Listing;
      });
    });
  return listings;
};

export const fetchListing = async (id: string) => {
  const listing: Listing | undefined = await db
    .collection("Listings")
    .doc(id)
    .get()
    .then((doc) => {
      const data = doc.data();
      if (data === undefined) {
        return;
      }
      return {
        id: doc.id,
        ...doc.data(),
      } as Listing;
    });
  return listing;
};

export const fetchProperty = async (id: string) => {
  const property: Property | undefined = await db
    .collection("Properties")
    .doc(id)
    .get()
    .then((doc) => {
      const data = doc.data();
      if (data === undefined) {
        return;
      }
      return data as Property;
    });
  return property;
};

export const fetchProjectListings = async (projectName: string) => {
  const listings: Array<Listing> = await db
    .collection("Listings")
    .where("Project.Project_Name", "==", projectName)
    .get()
    .then((querySnapshot) => {
      return querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        } as Listing;
      });
    });
  return listings;
};

export const fetchNearbyListings = async (
  lat: number,
  lng: number,
  radius: number
) => {
  //Fetch Related Listings by location
  // const lat = fetchedListing.Property.Location.latitude;
  // const lng = fetchedListing.Property.Location.longitude;
  const range = getGeohashRange(lat, lng, radius);
  const listings: Array<Listing> | void = await db
    .collection("Listings")
    .where("Property.Geohash", ">=", range.lower)
    .where("Property.Geohash", "<=", range.upper)
    .get()
    .then((querySnapshot) => {
      return querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        } as Listing;
      });
    })
    .catch((err) => console.log("ERROR: Couldn't get related listings", err));
  if (listings === undefined) {
    return [];
  }
  return listings;
};
