import { db, serverTime } from "../firebase";
import { Message } from "../newTypes";

export const createMessage = async (message: Message) => {
  const newMessage = {
    ...message,
    created_date: serverTime(),
    to: "realtorkings@gmail.com",
  };

  const success = await db
    .collection("Message")
    .add(newMessage)
    .then(() => true)
    .catch((error) => {
      console.error("ERROR: couldn't create Message: ", error);
    });

  return success !== undefined ? success : false;
};
