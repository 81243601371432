import React from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Card,
  CardContent,
  Paper,
  Typography,
  Grid,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useParams, useLocation } from "react-router";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { db } from "../firebase";
import { Listing, Builder, ImageType } from "../store/types";
import { RouteIdParam } from "../newTypes";
import { format, getGeohashRange } from "../helpers";
import {
  fetchListing,
  fetchProperty,
  fetchProjectListings,
} from "../api/ListingsAPI";
import { fetchBuilder } from "../api/BuilderAPI";
import { fetchConstructionStates } from "../api/TypesAPI";
import ListingList from "../components/listing/ListingList";
import ContactModal from "../components/dialogs/ContactDialogButton";
import ListingCarousel from "../components/listing/ListingCarousel";

const useStyles = makeStyles(() =>
  createStyles({
    fixedContainer: {
      maxWidth: "inherit",
      position: "absolute",
      padding: "0 64px",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row-reverse",
    },
    fixedItem: {
      position: "fixed",
      bottom: "1rem",
      zIndex: 3,
    },
  })
);

export default function ListingPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  let { id }: RouteIdParam = useParams();
  let location = useLocation();
  const [listing, setListing]: [Listing | undefined, any] = React.useState();
  const [images, setImages]: [ImageType | undefined, any] = React.useState();
  const [builder, setBuilder]: [Builder | undefined, any] = React.useState();
  const [constructionState, setConstructionState]: [
    string | undefined,
    any
  ] = React.useState();
  const [contractDate, setContractDate]: [
    string | undefined,
    any
  ] = React.useState();
  const [balcony, setBalcony]: [string | undefined, any] = React.useState();
  const [exposure, setExposure]: [string | undefined, any] = React.useState();
  const [relatedListings, setRelatedListings]: [
    Array<Listing>,
    any
  ] = React.useState([]);
  const [isLoadingRelated, setIsLoadingRelated] = React.useState(true);
  const [isHidden, setIsHidden]: [Boolean, any] = React.useState(false);

  const fetchData = async () => {
    var fetchedListing = await fetchListing(id).catch((e) => console.log(e));
    //Check if listing is found
    if (fetchedListing === undefined) {
      setIsHidden(true);
      return;
    } else {
      setIsHidden(false);
    }

    setListing(fetchedListing);
    setImages(fetchedListing.Images);

    //GET BUILDER
    const builder = fetchedListing.Builder;
    if (builder !== undefined) {
      const builderId = builder.Builder_ID.id;
      fetchBuilder(builderId).then((builder) => {
        if (builder === undefined) {
          return;
        }
        setBuilder(builder);
      });
    }

    //GET CONSTRUCTION STATE TYPE
    const constructionStateId = fetchedListing.Project.Construction_State_Type;
    fetchConstructionStates()
      .then((constructionStates) => {
        if (
          constructionStates !== undefined &&
          constructionStateId !== undefined
        ) {
          const constructionState = constructionStates[constructionStateId];
          setConstructionState(constructionState);
        }
      })
      .catch((e) => console.log(e));

    //GET Property
    const propertyId = fetchedListing.Property.Property_ID.id;
    fetchPropertyData(propertyId);

    //GET Related Listings
    fetchRelatedListings(fetchedListing.Project.Project_Name);
  };

  const fetchPropertyData = async (propertyId: string) => {
    const fetchedProperty = await fetchProperty(propertyId);
    if (fetchedProperty === undefined) {
      return;
    }
    setContractDate(dateString(fetchedProperty.Contract_Date.seconds));

    //GET Balcony Type
    db.collection("Types")
      .doc("Balcony")
      .get()
      .then((querySnapshot) => {
        const balconies = querySnapshot.data();
        const balconyId = fetchedProperty.Balcony_Type;
        if (balconyId !== undefined && balconies !== undefined) {
          const balcony = balconies[balconyId];
          setBalcony(balcony);
        }
      })
      .catch((err) => console.log("ERROR: Couldn't get Balconny Types", err));

    //GET Exposure Type
    db.collection("Types")
      .doc("Exposure")
      .get()
      .then((querySnapshot) => {
        const exposures = querySnapshot.data();
        const exposureId = fetchedProperty.Exposure_Type;
        if (exposureId !== undefined && exposures !== undefined) {
          const exposure = exposures[exposureId];
          setExposure(exposure);
        }
      })
      .catch((err) => console.log("ERROR: Couldn't get Exposure Types", err));
  };

  const fetchRelatedListings = async (projectName: string) => {
    //Fetch Listings in the same project
    var fetchedRelatedListings = await fetchProjectListings(projectName);
    //Filter out the current listing
    fetchedRelatedListings = fetchedRelatedListings.filter(
      (listing) => listing.id !== id
    );
    setRelatedListings(fetchedRelatedListings);
    setIsLoadingRelated(false);
  };

  React.useEffect(() => {
    //Scroll to top of page
    window.scrollTo(0, 0);

    //Reset View
    setImages(undefined);
    setListing(undefined);

    //Check if id is defined
    if (id === undefined) {
      setIsHidden(true);
      return;
    } else {
      setIsHidden(false);
    }

    fetchData();
  }, [location]);

  const dateString = (seconds?: number) => {
    if (seconds === undefined) {
      return "???";
    }
    let date = new Date(seconds * 1000);
    //Format Date
    const dateTimeFormat = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    const [
      { value: month },
      ,
      { value: day },
      ,
      { value: year },
    ] = dateTimeFormat.formatToParts(date);

    return `${month} ${day} ${year}`;
  };

  return (
    <Container maxWidth="lg">
      <div className={classes.fixedContainer}>
        <div className={classes.fixedItem}>
          {listing && <ContactModal listing={listing} id={id} />}
        </div>
      </div>
      <div style={{ display: isHidden ? "none" : "block" }}>
        <div style={{ margin: "1rem" }}>
          {images === undefined ? (
            <Skeleton
              variant="rect"
              animation="wave"
              height="35rem"
              width="100%"
            />
          ) : (
            <ListingCarousel images={images} />
          )}
        </div>
        <Card elevation={5} style={{ margin: "1rem" }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("For Sale")}
                </Typography>
                <Typography variant="h4" gutterBottom>
                  {listing === undefined ? (
                    <Skeleton />
                  ) : (
                    format(listing.Listing_Price)
                  )}
                </Typography>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                  {listing === undefined ? (
                    <Skeleton />
                  ) : (
                    listing.Property.Address
                  )}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Paper elevation={5} style={{ margin: "1rem" }}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t("Listing Information")}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2" color="textSecondary">
                    {t("Contract Date")}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {contractDate === undefined ? <Skeleton /> : contractDate}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t("Builder")}
                  </Typography>
                  <Typography variant="subtitle1">
                    {builder === undefined ? (
                      <Skeleton />
                    ) : (
                      builder.Builder_Name
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2" color="textSecondary">
                    {t("Occupancy Date")}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {listing === undefined ? (
                      <Skeleton />
                    ) : (
                      dateString(listing.Project?.Occupancy_Date.seconds)
                    )}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t("Project")}
                  </Typography>
                  <Typography variant="subtitle1">
                    {listing === undefined ? (
                      <Skeleton />
                    ) : (
                      listing.Project?.Project_Name
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2" color="textSecondary">
                    {t("Type")}
                  </Typography>
                  <Typography variant="subtitle1">
                    {constructionState === undefined ? (
                      <Skeleton />
                    ) : (
                      constructionState
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
        <Paper elevation={5} style={{ margin: "1rem" }}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t("Property Information")}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={3}>
                  <Typography variant="subtitle1" color="textSecondary">
                    {t("Bedrooms")}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {t("Bathrooms")}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {t("Parking")}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} style={{ textAlign: "start" }}>
                  <Typography variant="subtitle1">
                    {listing === undefined ? (
                      <Skeleton />
                    ) : (
                      listing.Property?.Bedrooms
                    )}
                  </Typography>
                  <Typography variant="subtitle1">
                    {listing === undefined ? (
                      <Skeleton />
                    ) : (
                      listing.Property?.Washrooms
                    )}
                  </Typography>
                  <Typography variant="subtitle1">
                    {listing === undefined ? (
                      <Skeleton />
                    ) : listing?.Property?.Parking ? (
                      "Yes"
                    ) : (
                      "No"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="subtitle1" color="textSecondary">
                    {t("Size")}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {t("Balcony Type")}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {t("Exposure Type")}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} style={{ textAlign: "start" }}>
                  <Typography variant="subtitle1">
                    {listing === undefined ? (
                      <Skeleton />
                    ) : (
                      <>
                        {listing.Property?.Property_SQFT} FT<sup>2</sup>
                      </>
                    )}
                  </Typography>
                  <Typography variant="subtitle1">
                    {balcony === undefined ? <Skeleton /> : balcony}
                  </Typography>
                  <Typography variant="subtitle1">
                    {exposure === undefined ? <Skeleton /> : exposure}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
        <Paper elevation={5} style={{ margin: "1rem" }}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t("Summary")}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {listing === undefined ? <Skeleton /> : listing.Note}
              </Typography>
            </CardContent>
          </Card>
        </Paper>
      </div>
      <div
        style={{
          display: isHidden ? "inherit" : "none",
          textAlign: "center",
          marginTop: "5rem",
        }}
      >
        <Typography variant="h4">
          {t("Uh oh. We can't find the listing you're looking for.")}
        </Typography>
        <Typography variant="h6" color="textSecondary">
          {t("Here are some other listings that might interest you")}
        </Typography>
      </div>
      <div style={{ marginTop: "5rem" }}>
        <ListingList
          title={t("Related")}
          isLoading={isLoadingRelated}
          listings={relatedListings}
        />
      </div>
    </Container>
  );
}
