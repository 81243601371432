import React from "react";
import { Container, Divider } from "@material-ui/core";
import { getGeocode, getLatLng } from "use-places-autocomplete";

import {
  Listing,
  FilterArgs,
  Coords,
  Project,
  ListOption,
} from "../store/types";
import { getGeohashRange } from "../helpers";
import { db } from "../firebase";
import BrowseList from "../components/browse/BrowseList";
import Search from "../components/browse/Search";

export default function BrowsePage() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [fetchedListings, setFetchedListings]: [
    Array<Listing>,
    any
  ] = React.useState([]);
  const [listings, setListings]: [Array<Listing>, any] = React.useState([]);

  /** ON INIT */

  //Get Initial Lisitngs
  React.useEffect(() => {
    fetchFeaturedListings();
  }, []);

  /** FETCHES */

  const fetchFeaturedListings = () => {
    //Get Featured & Assignment Sale Listings
    setIsLoading(true);
    db.collection("Listings")
      .where("Listing_Priority_Type", "<=", "101")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          const listing = doc.data();
          listing.id = doc.id;
          return listing;
        });
        setFetchedListings(data);
        setListings(sortListings(data as Array<Listing>));
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const fetchProjectListings = (project: Project) => {
    setIsLoading(true);
    db.collection("Listings")
      .where("Project.Project_Name", "==", project.Project_Name)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          const listing = doc.data();
          listing.id = doc.id;
          return listing;
        });
        setFetchedListings(data);
        setListings(sortListings(data as Array<Listing>));
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const fetchCoordListings = (coord: Coords) => {
    setIsLoading(true);
    //Restrict Query to within 2 miles
    const range = getGeohashRange(coord.lat, coord.lng, 2);
    db.collection("Listings")
      .where("Property.Geohash", ">=", range.lower)
      .where("Property.Geohash", "<=", range.upper)
      .limit(100)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          const tempListing = doc.data();
          tempListing.id = doc.id;
          return tempListing;
        });
        setFetchedListings(data);
        setListings(sortListings(data as Array<Listing>));
        setIsLoading(false);
      })
      .catch((err) => console.log("ERROR: Couldn't get listings", err))
      .finally(() => setIsLoading(false));
  };

  /** ACTIONS */

  //Handle user selecting an address, or project in the search bar.
  const handleOptionSelect = (option: ListOption) => {
    //Handle Search Bar Cleared
    if (option.place === undefined && option.project === undefined) {
      fetchFeaturedListings();
      return;
    }

    if (option.project !== undefined) {
      // setSelectedProject(option.project);
      fetchProjectListings(option.project);
      //getAddressListings(option.project.Project_Address);
    } else {
      // setSelectedProject(undefined);
    }

    if (option.place !== undefined) {
      getAddressListings(option.place.description);
      //setSelectedProject(undefined);
    }
  };

  const getAddressListings = async (address: string) => {
    if (address === "") {
      console.log("ERROR: No address provided to address search");
      return;
    }

    try {
      const results = await getGeocode({
        address,
      });
      const { lat, lng } = await getLatLng(results[0]);
      fetchCoordListings({ lat, lng });
    } catch (err) {
      console.log("ERROR: Could not get GeoCode", err);
    }
  };

  //** METHODS */

  //Return sorted listing array. Sort by higher priority and latest date
  const sortListings = (listingsToSort: Array<Listing>) => {
    const sortedList = [...listingsToSort];
    sortedList.sort((a, b) => {
      const aPriority = a.Listing_Priority_Type === "101";
      const bPriority = b.Listing_Priority_Type === "101";
      if (aPriority === bPriority) {
        //Same priority => sort by create date
        return a.Create_Date > b.Create_Date ? -1 : 1;
      }
      return aPriority === bPriority ? 0 : aPriority ? -1 : 1;
    });
    return sortedList;
  };

  const handleFilter = ({
    min,
    max,
    beds,
    baths,
    occupancy,
    hasParking,
  }: FilterArgs) => {
    const thisYear = new Date().getFullYear();
    const filteredListings = fetchedListings.filter((listing) => {
      const gtMin = min <= listing.Listing_Price;
      let ltMax = true;
      if (max <= 3000000) {
        ltMax = listing.Listing_Price <= max;
      }
      const ltBeds = beds <= listing.Property.Bedrooms;
      const ltBaths = baths <= listing.Property.Washrooms;
      let ltOccupancy = true;
      if (listing.Project?.Occupancy_Date !== undefined && occupancy <= 5) {
        let occupancyDate = new Date(
          listing.Project.Occupancy_Date.seconds * 1000
        );
        let yearDiff = occupancyDate.getFullYear() - thisYear;
        ltOccupancy = yearDiff <= occupancy;
      }
      const parking = hasParking ? listing.Property.Parking : true;
      const res = gtMin && ltMax && ltBeds && ltBaths && ltOccupancy && parking;
      return res;
    });
    setListings(sortListings(filteredListings));
  };

  return (
    <Container maxWidth="lg">
      <Search
        handleFilter={handleFilter}
        handleOptionSelect={handleOptionSelect}
      />
      <Divider style={{ margin: "0 2rem" }} />
      <BrowseList listings={listings} isLoading={isLoading} />
    </Container>
  );
}
