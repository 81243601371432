import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { db } from "../firebase";
import VIPMap from "../components/map/VIPMap";
import SideBar from "../components/map/SideBar";
import { Project, Listing, Coords } from "../store/types";
import { getGeohashRange } from "../helpers";

export default function MapPage() {
  const [selected, setSelected]: [Listing?, any?] = React.useState(undefined);
  const [hovered, setHovered]: [Listing?, any?] = React.useState(undefined);
  const [listings, setListings]: [Array<Listing>, any] = React.useState([]);
  const [location, setLocation]: [Coords, any] = React.useState({
    lat: 43.6536,
    lng: -79.384051,
  });

  React.useEffect(() => {
    //Restrict Query to within 8 miles
    const range = getGeohashRange(location.lat, location.lng, 8);
    db.collection("Listings")
      .where("Property.Geohash", ">=", range.lower)
      .where("Property.Geohash", "<=", range.upper)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          const tempListing = doc.data();
          tempListing.id = doc.id;
          return tempListing;
        });
        setListings(data);
      })
      .catch((err) => console.log("ERROR: Couldn't get Listings", err));
  }, [location]);

  const handlePlaceSelect = (coords: Coords) => {
    setLocation(coords);
  };

  const handleSelected = (selected?: Project) => {
    setSelected(selected);
  };

  const handleHovered = (hovered?: Project) => {
    setHovered(hovered);
  };

  return (
    <Grid
      container
      spacing={1}
      style={{ width: "100%", height: "90vh", marginTop: "1rem" }}
    >
      <Grid item sm={12} md={8} style={{ width: "inherit", height: "inherit" }}>
        <VIPMap
          listings={listings}
          barSelected={selected}
          handleSelect={handleSelected}
          hovered={hovered}
          handlePlaceSelect={handlePlaceSelect}
        />
      </Grid>
      <Hidden smDown>
        <Grid
          item
          sm={false}
          md={4}
          style={{ width: "inherit", height: "inherit" }}
        >
          <SideBar
            listings={listings}
            selected={selected}
            handleSelect={handleSelected}
            handleHover={handleHovered}
          />
        </Grid>
      </Hidden>
    </Grid>
  );
}
