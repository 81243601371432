import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Typography,
  Box,
} from "@material-ui/core";
import {
  KingBed,
  Bathtub,
  SquareFoot,
  LocalParking,
  Lock,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import { Listing } from "../../store/types";
import { format } from "../../helpers";

const ListingCard = (listing: Listing) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    //Scroll to top of page
    window.scrollTo(0, 0);
    history.push("/listing/" + listing.id);
  };

  return (
    <Card elevation={5} className={classes.card}>
      <CardActionArea style={{ minHeight: "22rem" }} onClick={handleClick}>
        {listing.Images?.Cover_Image === "" ? (
          <CardMedia
            component="img"
            alt="Cover Image"
            height={200}
            image={"/image-not-found.png"}
            title="Cover Image"
          />
        ) : (
          <CardMedia
            component="img"
            alt="Cover Image"
            height={180}
            image={listing.Images?.Cover_Image}
            title="Cover Image"
          />
        )}
        <CardContent>
          <Typography gutterBottom variant="h5">
            {format(listing.Listing_Price)}
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            {listing.Project.Project_Name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {listing.Property?.Address}
          </Typography>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ minHeight: "1rem" }}
          >
            {listing.Description}
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: "1rem" }}
          >
            <Chip
              variant="outlined"
              icon={<KingBed />}
              label={listing.Property?.Bedrooms}
              color="secondary"
            />
            <Chip
              variant="outlined"
              icon={<Bathtub />}
              label={listing.Property?.Washrooms}
              color="secondary"
            />
            <Chip
              variant="outlined"
              icon={<SquareFoot />}
              label={listing.Property?.Property_SQFT}
              color="secondary"
            />
            {listing.Property?.Parking && <LocalParking color="secondary" />}
            {listing.Property?.Locker && <Lock color="secondary" />}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      margin: "1rem",
      whiteSpace: "normal",
      maxWidth: "25rem",
      minWidth: "20rem",
      minHeight: "22rem",
    },
    item: {
      marginRight: theme.spacing(5),
    },
  })
);

export default ListingCard;
