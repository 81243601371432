import { db } from "../firebase";

export const fetchConstructionStates = async () => {
  const constructionStates = await db
    .collection("Types")
    .doc("Construction_State")
    .get()
    .then((querySnapshot) => {
      const data = querySnapshot.data();
      return data as any;
    })
    .catch((err) =>
      console.log("ERROR: Couldn't get Construction States", err)
    );
  return constructionStates;
};
