import React from "react";
import { InfoWindow } from "@react-google-maps/api";
import { Box, Typography, Chip, CardActionArea } from "@material-ui/core";
import Image from "material-ui-image";
import { useHistory } from "react-router-dom";
import {
  KingBed,
  Bathtub,
  SquareFoot,
  LocalParking,
  Lock,
} from "@material-ui/icons";

import { Listing, Coords } from "../../store/types";
import { shortPrice } from "../../helpers";
import "./ListingInfoWindow.css";

type ListingInfoProps = {
  listings: Array<Listing>;
  point: Coords;
  handleClose: () => void;
  constructionStates: any;
};

const ListingInfoWindow = ({
  listings,
  point,
  handleClose,
  constructionStates,
}: ListingInfoProps) => {
  const history = useHistory();

  const handleClick = (listing: Listing) => {
    history.push("/listing/" + listing.id);
  };

  return (
    <InfoWindow
      position={{
        lat: point.lat,
        lng: point.lng,
      }}
      onCloseClick={() => handleClose()}
    >
      <div style={{ maxHeight: "24rem" }}>
        {listings.map((listing) => (
          <CardActionArea onClick={() => handleClick(listing)}>
            <Box display="flex">
              <Box width={"8rem"} height={"8rem"}>
                <Image
                  src={
                    listing.Images?.Cover_Image === undefined
                      ? "error"
                      : listing.Images.Cover_Image === ""
                      ? "error"
                      : listing.Images.Cover_Image
                  }
                  animationDuration={1000}
                />
              </Box>
              <Box
                alignItems="flex-start"
                width={"14rem"}
                style={{ margin: "0.25rem 1rem" }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginBottom: "0.25rem" }}
                >
                  <Typography variant="h6" gutterBottom>
                    <b>{listing.Project.Project_Name}</b>
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    <b>{shortPrice(listing.Listing_Price)}</b>
                  </Typography>
                </Box>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {constructionStates[listing.Project.Construction_State_Type]}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {listing.Property.Address}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ margin: "0 0.25rem" }}
                >
                  <Chip
                    size="small"
                    variant="outlined"
                    icon={<KingBed />}
                    label={listing.Property.Bedrooms}
                    color="secondary"
                  />
                  <Chip
                    size="small"
                    variant="outlined"
                    icon={<Bathtub />}
                    label={listing.Property.Washrooms}
                    color="secondary"
                  />
                  <Chip
                    size="small"
                    variant="outlined"
                    icon={<SquareFoot />}
                    label={listing.Property.Property_SQFT}
                    color="secondary"
                  />
                  {listing.Property.Parking && (
                    <LocalParking fontSize="small" color="secondary" />
                  )}
                  {listing.Property.Locker && (
                    <Lock fontSize="small" color="secondary" />
                  )}
                </Box>
              </Box>
            </Box>
          </CardActionArea>
        ))}
      </div>
    </InfoWindow>
  );
};

export default ListingInfoWindow;
