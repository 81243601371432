import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Drawer,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import {
  Apartment,
  ContactMail,
  ExitToApp,
  Home,
  Info,
  Menu,
  Pageview,
  Security,
} from "@material-ui/icons";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import LoginDialogButton from "../dialogs/LoginDialogButton";
import { auth } from "../../firebase";
import { RootState } from "../../store";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    drawer: {
      width: drawerWidth,
    },
    bar: {
      backgroundColor: blueGrey[500],
    },
    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

function SideDrawer() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const user = useSelector((state: RootState) => state.userReducer.user);

  const drawerItems = [
    {
      title: t("Home"),
      icon: <Home />,
      handler: () => {
        history.push("/");
        setMobileOpen(false);
      },
    },
    {
      title: t("Browse"),
      icon: <Pageview />,
      handler: () => {
        history.push("/browse");
        setMobileOpen(false);
      },
    },
    {
      title: t("Contact Us"),
      icon: <ContactMail />,
      handler: () => {
        history.push("/contact");
        setMobileOpen(false);
      },
    },
    {
      title: t("About Us"),
      icon: <Info />,
      handler: () => {
        history.push("/about");
        setMobileOpen(false);
      },
    },
    {
      title: t("List My Property"),
      icon: <Apartment />,
      handler: () => {
        history.push("/listmyproperty");
        setMobileOpen(false);
      },
    },
    {
      title: t("Privacy"),
      icon: <Security />,
      handler: () => {
        history.push("/privacy");
        setMobileOpen(false);
      },
    },
  ];

  const handleSignOut = () => {
    auth
      .signOut()
      .then(function () {
        console.log("Signout Sucessful");
        setMobileOpen(false);
      })
      .catch(function (error) {
        // An error happened.
        console.log(error);
      });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBar position="static" className={classes.bar}>
        <Toolbar>
          <IconButton onClick={() => setMobileOpen(true)} color="primary">
            <Menu />
          </IconButton>
          <Box
            style={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
              marginRight: "2rem",
            }}
          >
            <img
              src="/vip_logo.png"
              alt="VIP Assignment logo"
              width={25}
              height={25}
            />
            <Typography variant="h6" style={{ marginLeft: "1rem" }}>
              {t("VIP Assignment")}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        classes={{
          paper: classes.drawer,
        }}
      >
        <div className={classes.toolbar}>
          <img
            src="/vip_logo.png"
            alt="VIP Assignment logo"
            width={25}
            height={25}
          />
        </div>
        <Divider />
        <Box
          style={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <List>
            {drawerItems.map((drawerItem) => (
              <ListItem
                button
                key={drawerItem.title}
                onClick={drawerItem.handler}
              >
                <ListItemIcon>{drawerItem.icon}</ListItemIcon>
                <ListItemText primary={drawerItem.title} />
              </ListItem>
            ))}
          </List>
          {user === undefined ? (
            <LoginDialogButton />
          ) : (
            <ListItem button onClick={handleSignOut}>
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText
                primary={t("Sign Out")}
                secondary={
                  user === undefined
                    ? ""
                    : user.Name === undefined
                    ? ""
                    : user.Name
                }
              />
            </ListItem>
          )}
        </Box>
      </Drawer>
    </>
  );
}

export default SideDrawer;
