import React from "react";
import { useTranslation } from "react-i18next";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Container,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import MessageIcon from "@material-ui/icons/Message";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import { RootState } from "../store";
import { validatePhone, validateEmail } from "../helpers";
import { db } from "../firebase";
import { Message } from "../newTypes";
import { createMessage } from "../api/MessageAPI";

const ContactPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();

  const [showError, setShowError] = React.useState(false);
  const [showSucess, setShowSuccess] = React.useState(false);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [isEmailValid, setIsEmailValid] = React.useState(true);
  const [isPhoneValid, setIsPhoneValid] = React.useState(true);
  const [isContactUs, setIsContactUs] = React.useState(true);
  const [title, setTitle] = React.useState("Contact Us");
  const user = useSelector((state: RootState) => state.userReducer.user);

  React.useEffect(() => {
    if (user !== undefined) {
      setEmail(user.Email);
      setName(user.Name !== undefined ? user.Name : name);
      setPhone(user.Phone !== undefined ? user.Phone : phone);
    }
  }, [user]);

  React.useEffect(() => {
    if (location.pathname === "/listmyproperty") {
      setMessage(
        "Hi, I would like to list my property on Vipassignment.com, please contact me via email or phone."
      );
      setIsContactUs(false);
      setTitle("List My Property");
    } else {
      setMessage("");
      setIsContactUs(true);
      setTitle("Contact Us");
    }
  }, [location]);

  const handleSnackClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowError(false);
    setShowSuccess(false);
  };

  const handleSubmit = () => {
    if (email === "" || !isEmailValid || phone === "" || !isPhoneValid) {
      setShowError(true);
      return;
    }

    createContactMessage();
  };

  const createContactMessage = async () => {
    let newMessage: Message = {
      contact_email: email,
      contact_name: name,
      contact_phone: phone,
      message: {
        subject: `New Message from ${name} on VVIPLiving.com`,
        html: message,
      },
      type: "General",
    };

    if (isContactUs) {
      newMessage.type = "General";
    } else {
      newMessage.type = "Owner";
    }

    setShowBackdrop(true);

    const success = await createMessage(newMessage)
      .catch((e) => console.log(e))
      .finally(() => {
        setShowBackdrop(false);
      });
    if (success) {
      setShowSuccess(true);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography
        variant="h4"
        style={{ textAlign: "center", marginTop: "3rem" }}
        gutterBottom
      >
        {title}
      </Typography>
      <Grid container spacing={1} style={{ margin: "2rem 0" }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" color="textSecondary">
            Unit 302- 235 Yorkland Blvd
          </Typography>
          <Typography variant="h6" color="textSecondary">
            North York, ON M2J 4Y8
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.rightText}>
          <Typography variant="h6" color="textSecondary">
            647-299-8347
          </Typography>
          <Typography variant="h6" color="textSecondary">
            Contact_us@vvipliving.com
          </Typography>
        </Grid>
      </Grid>
      <div style={{ margin: "2rem 0" }}>
        <form noValidate>
          <TextField
            color="secondary"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("Email Address")}
            name="email"
            autoComplete="email"
            defaultValue={email}
            onChange={(event) => {
              setEmail(event.target.value);
              setIsEmailValid(validateEmail(event.target.value));
            }}
            error={!isEmailValid}
          />
          <TextField
            color="secondary"
            variant="outlined"
            margin="normal"
            fullWidth
            name="name"
            label={t("Name")}
            id="name"
            autoComplete="name"
            defaultValue={name}
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            color="secondary"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            name="phone"
            label={t("Phone")}
            id="phone"
            autoComplete="tel"
            defaultValue={phone}
            onChange={(event) => {
              setPhone(event.target.value);
              setIsPhoneValid(validatePhone(event.target.value));
            }}
            error={!isPhoneValid}
          />
          <TextField
            color="secondary"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="message"
            label={t("Message")}
            value={message}
            multiline
            rows={5}
            onChange={(event) => setMessage(event.target.value)}
          />
          <Box
            display="flex"
            justifyContent="center"
            style={{ marginBottom: "1rem" }}
          >
            <Button
              variant="contained"
              size="large"
              color="primary"
              startIcon={<MessageIcon />}
              onClick={handleSubmit}
              style={{ marginTop: "1rem" }}
            >
              {t("Send Message")}
            </Button>
          </Box>
        </form>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={showError}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert severity="error" variant="filled">
          {t("Missing required field!")}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={showSucess}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert severity="success" variant="filled">
          {t("Message sent!")}
        </Alert>
      </Snackbar>
      <Backdrop open={showBackdrop} style={{ zIndex: 1000 }}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Container>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightText: {
      [theme.breakpoints.down("xs")]: {
        textAlign: "start",
      },
      [theme.breakpoints.up("sm")]: {
        textAlign: "end",
      },
    },
  })
);

export default ContactPage;
