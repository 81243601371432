import { UserState, LOGIN, UserActionTypes } from "./types";

export function login(newUser: UserState): UserActionTypes {
  return {
    type: LOGIN,
    payload: newUser,
  };
}

export function logout(newUser: UserState): UserActionTypes {
  return {
    type: LOGIN,
    payload: newUser,
  };
}
