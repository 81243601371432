import { User } from "../types";

export interface UserState {
  user?: User;
  isLoggedIn: boolean;
}

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

interface LoginAction {
  type: typeof LOGIN;
  payload: UserState;
}

interface LogoutAction {
  type: typeof LOGOUT;
  payload: UserState;
}

export type UserActionTypes = LoginAction | LogoutAction;
