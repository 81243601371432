import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import ExitIcon from "@material-ui/icons/ExitToApp";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { useSelector } from "react-redux";

import LoginDialogButton from "../dialogs/LoginDialogButton";
import { auth } from "../../firebase";
import { RootState } from "../../store";

const NavBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const user = useSelector((state: RootState) => state.userReducer.user);

  const onMap = () => {
    history.push("/map");
  };

  const onBrowse = () => {
    history.push("/browse");
  };

  //Respond to User Login or Sign Out
  React.useEffect(() => {
    if (user === undefined) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, [user]);

  const handleSignOut = () => {
    auth
      .signOut()
      .then(function () {
        console.log("Signout Sucessful");
      })
      .catch(function (error) {
        // An error happened.
        console.log(error);
      });
  };

  const handleContactUs = () => {
    //Scroll to top of page
    window.scrollTo(0, 0);
    history.push("/contact");
  };

  return (
    <AppBar position="static" className={classes.bar}>
      <div
        className={"MuiToolbar-regular"}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: "100%", zIndex: 1 }}
        >
          <Hidden smDown>
            <Typography variant="h6">{t("VIP Assignment")}</Typography>
          </Hidden>
        </Box>
      </div>
      <Toolbar>
        <Link to="/">
          <IconButton>
            <img
              src="/vip_logo.png"
              alt="VIP Assignment logo"
              width={30}
              height={30}
            />
          </IconButton>
        </Link>
        <Button color="inherit" onClick={onMap}>
          {t("Map")}
        </Button>
        <Button color="inherit" onClick={onBrowse}>
          {t("Browse")}
        </Button>
        <Button color="inherit" onClick={handleContactUs}>
          {t("Contact Us")}
        </Button>
        <Box display="flex" flexGrow={1} justifyContent="center"></Box>
        {isLoggedIn ? (
          <>
            <Button
              color="inherit"
              onClick={handleSignOut}
              startIcon={<ExitIcon />}
            >
              Sign Out{" "}
              {user === undefined
                ? ""
                : user.Name === undefined
                ? ""
                : user.Name}
            </Button>
          </>
        ) : (
          <LoginDialogButton />
        )}
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    bar: {
      backgroundColor: blueGrey[500],
    },
  })
);

export default NavBar;
