import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog } from "@material-ui/core";
import AccountIcon from "@material-ui/icons/AccountCircle";

import RegisterForm from "./RegisterForm";
import LoginForm from "./LoginForm";

export default function LoginDialogButton() {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(true);

  return (
    <>
      <Button
        color="inherit"
        startIcon={<AccountIcon />}
        onClick={() => setOpen(true)}
      >
        {t("Sign up / Login")}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
      >
        {isLogin ? (
          <LoginForm
            handleClose={() => setOpen(false)}
            handleToggle={() => setIsLogin(!isLogin)}
          />
        ) : (
          <RegisterForm
            handleClose={() => setOpen(false)}
            handleToggle={() => setIsLogin(!isLogin)}
          />
        )}
      </Dialog>
    </>
  );
}
