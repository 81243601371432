import React from "react";
import { Marker } from "@react-google-maps/api";

import { Listing } from "../../store/types";
import { format } from "../../helpers";

type ListingPinProps = {
  listing: Listing;
  onListingClick: () => void;
  isHovered: boolean;
  clusterer: any;
};

const ListingPin = ({
  listing,
  onListingClick,
  isHovered,
  clusterer,
}: ListingPinProps) => {
  const [isHover, setIsHover] = React.useState(false);
  //Icon is google.maps.Icon object
  const [icon, setIcon] = React.useState({
    anchor: new window.google.maps.Point(30, 0), //Where the InfoWidow Appears
    labelOrigin: new window.google.maps.Point(30, 14),
    url: "/marker.svg",
    scaledSize: new window.google.maps.Size(60, 35),
    origin: new window.google.maps.Point(0, 0),
  });
  //Label is google.maps.MarkerLabel
  const [label, setLabel]: [google.maps.MarkerLabel, any] = React.useState({
    color: "white",
    text: "",
    fontWeight: "bold",
  });

  React.useEffect(() => {
    //If marker is hovered (or hovered in the side list), the icon and text are bigger
    if (isHover || isHovered) {
      setIcon({
        anchor: new window.google.maps.Point(35, 0), //Where the InfoWidow Appears
        labelOrigin: new window.google.maps.Point(35, 15),
        url: "/marker.svg",
        scaledSize: new window.google.maps.Size(75, 45),
        origin: new window.google.maps.Point(0, 3),
      });
      setLabel({
        color: "white",
        text: formatPrice(listing.Listing_Price),
        fontSize: "18px",
        fontWeight: "bolder",
      });
    } else {
      setIcon({
        anchor: new window.google.maps.Point(30, 0), //Where the InfoWidow Appears
        labelOrigin: new window.google.maps.Point(30, 14),
        url: "/marker.svg",
        scaledSize: new window.google.maps.Size(60, 35),
        origin: new window.google.maps.Point(0, 0),
      });
      setLabel({
        color: "white",
        text: formatPrice(listing.Listing_Price),
        fontSize: "14px",
        fontWeight: "bold",
      });
    }
  }, [isHover, isHovered]);

  //Format large price values to short text
  const formatPrice = (num: number) => {
    if (num > 999999) {
      return "$" + (num / 1000000).toFixed(2) + "m";
    } else if (num > 999) {
      return "$" + num / 1000 + "k";
    } else {
      return format(num);
    }
  };

  return (
    <Marker
      clusterer={clusterer}
      position={{
        lat: listing.Property.Location.latitude,
        lng: listing.Property.Location.longitude,
      }}
      icon={icon}
      label={label}
      onClick={onListingClick}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      // title={listing.id}
    />
  );
};

export default ListingPin;
