import { UserState, UserActionTypes, LOGIN, LOGOUT } from "./types";

const initialState: UserState = {
  isLoggedIn: false,
  user: undefined,
};

export function userReducer(
  state = initialState,
  action: UserActionTypes
): UserState {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
