import React from "react";
//import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Container } from "@material-ui/core";

const ContactPage = () => {
  const classes = useStyles();
  //const { t } = useTranslation();

  return (
    <Container maxWidth="md">
      <Box className={classes.titleContainer}>
        <img
          src="/vip_logo.png"
          alt="VIP Assignment logo"
          width={45}
          height={45}
        />
        <Typography variant="h4" className={classes.title} gutterBottom>
          About Us
        </Typography>
      </Box>

      <Typography variant="h6" gutterBottom>
        The vibrancy of city life in modern communities demands a sense of
        relevance, belonging, respect and engagements compared to the rural
        living zip codes for work, leisure, health and lifestyle.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Virtual connectivity has had tremendous benefits for productivity and
        quality of life, and online communities are increasingly replacing
        face-to-face interaction.
      </Typography>
      <Typography variant="h6" gutterBottom>
        VVIP Living.com aims at connecting people both face-to-face and online
        when they are involved in the decisions of major investment regarding
        their city life. For instance, people spend a great deal of time living,
        working, and shopping in large-scale real-estate properties in cities.
        Our applications help them to make decisions about the property they
        have and provide professional opinions on the property, or to provide
        assistance with their purchase decisions.
      </Typography>
      <Typography variant="h6" gutterBottom>
        We are confident we have an innovative model to engage all walks of life
        when it comes to investment decisions of their city living, we look
        forward to understanding and assisting your needs.
      </Typography>
    </Container>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    title: { textAlign: "center", margin: "3rem 0.5rem" },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

export default ContactPage;
