import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import HomeIcon from "@material-ui/icons/Home";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { useTranslation } from "react-i18next";

import { Project, ListOption, PlaceType } from "../../store/types";

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

type PlaceSelectProps = {
  handleOptionSelect: (option: ListOption) => void;
  projects: Array<Project>;
};

const PlaceSelect = ({ handleOptionSelect, projects }: PlaceSelectProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = React.useState<ListOption | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<ListOption[]>([]);
  //Set lat lng to Toronto
  const myLatLng = new google.maps.LatLng({ lat: 43.6536, lng: -79.384051 });

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: {
            input: string;
            location: google.maps.LatLng;
            radius: number;
          },
          callback: (results?: PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        200
      ),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      //If no input, show list of projects
      const projectOptions: Array<ListOption> = projects.map((project) => {
        return { project };
      });
      setOptions(projectOptions);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        location: myLatLng,
        radius: 40000,
      },
      (results?: PlaceType[]) => {
        if (active) {
          let newOptions = [] as PlaceType[];

          if (value && value.place) {
            newOptions = [value.place];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          const listOptions: Array<ListOption> = newOptions.map((option) => {
            return { place: option };
          });

          const filteredProjects = projects.filter((project) => {
            return project.Project_Name.toLowerCase().includes(
              inputValue.toLowerCase()
            );
          });

          const projectListOptions: Array<ListOption> = filteredProjects.map(
            (project) => {
              return { project };
            }
          );

          listOptions.splice(
            0,
            projectListOptions.length,
            ...projectListOptions
          );

          setOptions(listOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      style={{ width: "100%" }}
      id="google-map-demo"
      getOptionLabel={(option) => {
        if (option.place) {
          return option.place.description;
        } else if (option.project !== undefined) {
          return option.project.Project_Name;
        } else {
          return "";
        }
      }}
      options={options}
      autoComplete
      filterSelectedOptions
      getOptionSelected={(option: ListOption, value: ListOption) => {
        if (option.place) {
          if (value.place) {
            return option.place.description === value.place.description;
          }
          return false;
        }
        if (option.project) {
          if (value.project) {
            return option.project.Project_Name === value.project.Project_Name;
          }
          return false;
        }
        return false;
      }}
      value={value}
      onChange={(event: any, newValue: ListOption | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (newValue === null) {
          handleOptionSelect({ project: undefined, place: undefined });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("Project Name or Address")}
          variant="outlined"
          color="secondary"
          fullWidth
          style={{ backgroundColor: "white" }}
        />
      )}
      renderOption={(option) => {
        if (option.place) {
          const matches =
            option.place.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.place.structured_formatting.main_text,
            matches.map((match: any) => [
              match.offset,
              match.offset + match.length,
            ])
          );
        }

        return (
          <Grid
            container
            alignItems="center"
            onClick={() => handleOptionSelect(option)}
          >
            <Grid item>
              {option.place ? (
                <LocationOnIcon className={classes.icon} />
              ) : (
                <HomeIcon className={classes.icon} />
              )}
            </Grid>
            <Grid item xs>
              {option.place
                ? option.place.structured_formatting.main_text
                : option.project
                ? option.project.Project_Name
                : ""}
              {/* {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))} */}
              <Typography variant="body2" color="textSecondary">
                {option.place
                  ? option.place.structured_formatting.secondary_text
                  : option.project
                  ? option.project.Project_Address
                  : ""}
                {/* {option.structured_formatting.secondary_text} */}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default PlaceSelect;
