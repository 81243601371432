import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // required
import { Carousel } from "react-responsive-carousel";
import Image from "material-ui-image";

import { Listing } from "../../store/types";
import { format } from "../../helpers";

type VIPListingProps = {
  listings: Array<Listing>;
};

const VIPListing = ({ listings }: VIPListingProps) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (listing: Listing) => {
    history.push("/listing/" + listing.id);
  };

  return (
    <div
      className={classes.divHeight}
      style={{ width: "100%", backgroundColor: "white" }}
    >
      {listings.length === 0 ? (
        <Skeleton
          animation="wave"
          variant="rect"
          width={"100%"}
          height={"100%"}
        />
      ) : (
        <Carousel
          width="100%"
          dynamicHeight={false}
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={5000}
        >
          {listings.map((listing) => (
            <div
              key={listing.id}
              className={classes.divHeight}
              onClick={() => handleClick(listing)}
            >
              <Image
                src={
                  listing.Images === undefined
                    ? "error"
                    : listing.Images.Cover_Image === ""
                    ? "error"
                    : listing.Images.Cover_Image
                }
                aspectRatio={2 / 1}
                imageStyle={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  objectFit: "cover",
                }}
                style={{
                  height: "35rem",
                }}
              />
              <div className={classes.imageDiv}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.textDiv}
                >
                  <Box>
                    <Typography variant="h4" style={{ textAlign: "left" }}>
                      {listing.Description}
                    </Typography>
                    <Typography variant="h5" style={{ textAlign: "left" }}>
                      {listing.Project.Project_Name}
                    </Typography>
                  </Box>
                  <Typography variant="h4">
                    {format(listing.Listing_Price)}
                  </Typography>
                </Box>
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    imageDiv: {
      position: "absolute",
      height: "7rem",
      width: "100%",
      bottom: 0,
      backgroundColor: "rgba(84,110,122,0.6)",
      color: "white",
    },
    textDiv: {
      margin: "0rem 2rem",
      height: "100%",
      fontWeight: "bold",
    },
    divHeight: {
      height: "35rem",
    },
  })
);

export default VIPListing;
