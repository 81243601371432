import React from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";

import { Listing } from "../../store/types";
import { db } from "../../firebase";
import { fetchConstructionStates } from "../../api/TypesAPI";
import BrowseIem from "./BrowseItem";

type BowseListProps = {
  isLoading: boolean;
  listings: Array<Listing>;
};

export default function BrowseList({ isLoading, listings }: BowseListProps) {
  const [constructionStates, setConstructionStates]: [
    any,
    any?
  ] = React.useState([]);

  const fetchData = async () => {
    const fetchedStates = await fetchConstructionStates().catch((e) =>
      console.log(e)
    );
    setConstructionStates(fetchedStates);
  };

  React.useEffect(() => {
    //GET CONSTRUCTION STATE TYPE
    // db.collection("Types")
    //   .doc("Construction_State")
    //   .get()
    //   .then((querySnapshot) => {
    //     const constructionStates = querySnapshot.data();
    //     setConstructionStates(constructionStates);
    //   })
    //   .catch((err) =>
    //     console.log("ERROR: Couldn't get Construction States", err)
    //   );
  }, []);

  //TODO: Lazy load listing images
  return (
    <div>
      {isLoading ? (
        <Box
          display="flex"
          flex={1}
          justifyContent="center"
          style={{ marginTop: "1rem" }}
        >
          <CircularProgress />
        </Box>
      ) : listings.length === 0 ? (
        <Box
          display="flex"
          flex={1}
          justifyContent="center"
          style={{ marginTop: "1rem" }}
        >
          <Typography variant="h5" color="textSecondary">
            No Listings Found
          </Typography>
        </Box>
      ) : undefined}
      {listings.map((listing) => (
        <BrowseIem
          key={listing.id}
          listing={listing}
          constructionState={
            listing.Project?.Construction_State_Type === undefined
              ? ""
              : constructionStates[listing.Project.Construction_State_Type]
          }
        />
      ))}
    </div>
  );
}
