import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { GridList, GridListTile } from "@material-ui/core";

import { db } from "../../firebase";
import SideListItem from "./SideBarItem";
import { Listing } from "../../store/types";

type SideBarProps = {
  listings: Array<Listing>;
  selected?: Listing;
  handleSelect: any;
  handleHover: any;
};

const SideBar = ({
  listings,
  selected,
  handleSelect,
  handleHover,
}: SideBarProps) => {
  const classes = useStyles();
  const [constructionStates, setConstructionStates]: [
    Array<string>,
    any?
  ] = React.useState([]);

  React.useEffect(() => {
    //GET CONSTRUCTION STATE TYPE
    db.collection("Types")
      .doc("Construction_State")
      .get()
      .then((querySnapshot) => {
        const constructionStates = querySnapshot.data();
        setConstructionStates(constructionStates);
      })
      .catch((err) =>
        console.log("ERROR: Couldn't get Construction States", err)
      );
  }, []);

  return (
    <>
      <GridList
        className={classes.container}
        cols={1}
        spacing={4}
        cellHeight={"auto"}
      >
        {listings.map((listing) => (
          <GridListTile key={listing.id} cols={1}>
            <SideListItem
              listing={listing}
              isSelected={selected !== undefined && selected.id === listing.id}
              handleSelect={handleSelect}
              handleHover={handleHover}
              constructionState={
                listing.Construction_State_Type === undefined
                  ? ""
                  : constructionStates[listing.Construction_State_Type]
              }
            />
          </GridListTile>
        ))}
      </GridList>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: "1rem",
    },
    title: {
      marginLeft: "2rem",
    },
    container: {
      width: "100%",
      height: "100%",
    },
    gridList: {
      width: 500,
      height: 450,
    },
  })
);

export default SideBar;
