import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDNkV2fZ2P0Bjts9TbHIPQxQjsxLBxtEMY",
  authDomain: "vipassignment-37654.firebaseapp.com",
  databaseURL: "https://vipassignment-37654.firebaseio.com",
  projectId: "vipassignment-37654",
  storageBucket: "vipassignment-37654.appspot.com",
  messagingSenderId: "729663704733",
  appId: "1:729663704733:web:55680f20fbfc8e506ece74",
  measurementId: "G-0HEYCLJNY0",
});

const db = firebaseApp.firestore();
const auth = firebaseApp.auth();

const serverTime = firebase.firestore.FieldValue.serverTimestamp;

export { db, auth, serverTime };
